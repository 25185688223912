
























import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
    name: "TimeLog",
})
export default class TimeLog extends Vue {

    @Prop()
    protected day!: number;

    @Prop()
    protected month!: string;

    @Prop({ default: "Quest Log" })
    protected title!: string;

    @Prop({ default: "" })
    protected description?: string;

    @Prop({ default: "white" })
    protected backgroundColor!: string;

    get bgColor() {
        return this.backgroundColor
    }
}
